import React from 'react';
import { RiSignalWifiErrorLine } from 'react-icons/ri';

export const CustomToastNetworkError: React.FC = (): JSX.Element => {
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'auto 1fr',
        alignItems: 'center',
        gap: '0.5rem',
        color: 'white',
      }}
    >
      <RiSignalWifiErrorLine size={30} color="white" />
      <div style={{ marginTop: '0.9375rem' }}>
        <p>
          Verifique sua INTERNET. Conexão de internet está fraca ou ausente.
        </p>
      </div>
    </div>
  );
};
